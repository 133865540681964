import {useContext} from 'react';

import {TeamOrgQuery} from '../../generated/graphql';
import {useCurrentTeamOrg} from '../../util/nudgeBars';
import {useRampFlagAccountSelector} from '../../util/rampFeatureFlags';
import {AccountSelectorContext} from '../Search/SearchNav/AccountSelectorContextProvider';
import {GlobalBannerContext} from './GlobalBannerContextProvider';
import {GlobalBannerState} from './types';

export function useCurrentBannerStates(): GlobalBannerState[] {
  const currentTeamOrg: TeamOrgQuery | null = useCurrentTeamOrg();

  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const {states} = useContext(GlobalBannerContext);

  if (states == null || states.length === 0) {
    return [];
  }

  if (enableAccountSelector) {
    return states.filter(state => state.account.name === selectedAccount?.name);
  }
  if (currentTeamOrg != null) {
    return states.filter(
      state =>
        state.organization?.name === currentTeamOrg.entity?.organization?.name
    );
  }
  return states;
}
