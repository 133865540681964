import {TargetBlank} from '@wandb/weave/common/util/links';
import {Button} from '@wandb/weave/components';
import numeral from 'numeral';
import React from 'react';

import {apolloClient as client} from '../../../apolloClient';
import {envIsLocal} from '../../../config';
import {
  EnterpriseBillingPeriodOrgInfoQuery,
  UsageType,
  WeaveUsageInfoQuery,
} from '../../../generated/graphql';
import {getIntervalMarkers} from '../../../pages/Billing/AccountSettings/BillingTab/CurrentBillingPeriodUsageSection';
import {ENTERPRISE_BILLING_PERIOD_ORG_INFO} from '../../../pages/Billing/AccountSettings/BillingTab/CurrentBillingPeriodUsageSection.query';
import {canSeeTrialEndPage} from '../../../pages/Billing/AccountSettings/WarningBanner/ExpiryBannerContent';
import {PlanName} from '../../../pages/Billing/util';
import {TODAY} from '../../../pages/HistoricUsage/Meter/MeterSection';
import {WEAVE_USAGE_INFO} from '../../../pages/HistoricUsage/UsageTabContent.query';
import {TRIAL_END_PATH} from '../../../routes/paths';
import {
  BillingPeriod,
  getAccountPrivileges,
  getAccountWeaveLimit,
  getPrimarySub,
  getWeaveOverageSubBillingPeriod,
  isProPrimarySubscription,
  isTrialPlanPrimaryPlan,
  Privileges,
} from '../../../util/accounts/pricing';
import {differenceInDays} from '../../../util/date';
import * as urls from '../../../util/urls';
import {Account, AccountType} from '../../Search/SearchNav/types';
import {ViewerOrg} from '../types';
import {WeaveEnforcementBannerFlags} from './types';

async function getWeaveUsageBytes(
  account: Account,
  billingPeriod: BillingPeriod | undefined,
  viewerOrg: ViewerOrg | undefined
): Promise<number> {
  const response = await client.query<WeaveUsageInfoQuery>({
    query: WEAVE_USAGE_INFO,
    fetchPolicy: 'network-only',
    variables: {
      orgName: account.name,
      usageType: UsageType.Weave,
      intervalMarkers: getIntervalMarkers(billingPeriod, UsageType.Weave),
    },
  });
  const usage = response.data?.organization?.usageTotal?.[0] ?? 0;

  return usage;
}

async function getHasEnterpriseOrbSubscription(
  account: Account
): Promise<boolean> {
  const response = await client.query<EnterpriseBillingPeriodOrgInfoQuery>({
    query: ENTERPRISE_BILLING_PERIOD_ORG_INFO,
    fetchPolicy: 'network-only',
    variables: {
      orgName: account.name,
    },
  });
  const hasEnterpriseOrbSubscription =
    response.data?.organization?.weaveEnterpriseSubscription != null &&
    response.data?.organization?.weaveEnterpriseSubscription
      ?.billingPeriodStart != null;
  return hasEnterpriseOrbSubscription;
}

export function ContactSalesCTA({shouldUnderline = true}) {
  return (
    <TargetBlank
      href={urls.contactSalesWeaveTrialLimitReached()}
      className={`font-semibold ${shouldUnderline ? 'underline' : ''}`}>
      Contact sales
    </TargetBlank>
  );
}

export function UpgradePlanCTA({shouldUnderline = true}) {
  return (
    <TargetBlank
      href={TRIAL_END_PATH}
      className={`font-semibold ${shouldUnderline ? 'underline' : ''}`}>
      Upgrade plan
    </TargetBlank>
  );
}

function getApproachingLimitStatusMsg(
  planName: PlanName,
  weaveOverageCostCents: number | undefined,
  weaveOverageUnit: string | undefined
) {
  if (planName === 'pro_plan_annual') {
    const formattedCost = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format((weaveOverageCostCents ?? 0) / 100);

    return `Each additional ${weaveOverageUnit} will be charged at ${formattedCost} per ${weaveOverageUnit}. `;
  }
  return 'Once you exceed your limit, access to Weave will be restricted. ';
}

function getExceededLimitStatusMsgs(
  planName: PlanName,
  weaveUsageBytes: number | undefined,
  weaveLimitBytes: number | undefined,
  weaveOverageCostCents: number | undefined,
  weaveOverageUnit: string | undefined
) {
  const limitFormatted = numeral(weaveLimitBytes).format('0 b');
  const usageFormatted = numeral(weaveUsageBytes).format('0 b');
  const formattedCost = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format((weaveOverageCostCents ?? 0) / 100);
  let exceededLimitBoldStatusMsg = `Your organization has exceeded its ${limitFormatted} free data ingestion limit for Weave.`;
  if (planName === 'community_edition') {
    exceededLimitBoldStatusMsg = `Your organization has exceeded its ${limitFormatted}/month free data ingestion limit for Weave.`;
  } else if (
    planName === 'pro_plan_annual' ||
    planName === 'yearly_teams_2023'
  ) {
    exceededLimitBoldStatusMsg = `Your organization has used ${usageFormatted} of its ${limitFormatted}/mo Weave data ingestion.`;
  }

  let exceededLimitStatusMsg = 'Access to Weave will be restricted. ';
  if (planName === 'community_edition') {
    exceededLimitStatusMsg = `You've used ${usageFormatted} this month. `;
  } else if (planName === 'pro_plan_annual') {
    exceededLimitStatusMsg = `Each additional ${weaveOverageUnit} will be charged at ${formattedCost} per ${weaveOverageUnit}. `;
  }
  return {exceededLimitBoldStatusMsg, exceededLimitStatusMsg};
}

function getExceededLimitCTA(
  planName: PlanName,
  ctaNode: React.ReactNode,
  onDismiss?: () => void
): React.ReactNode {
  if (planName === 'yearly_teams_2023') {
    return (
      <div className="ml-auto flex items-center gap-8">
        <Button
          variant="outline"
          className="border-moon-750 bg-transparent text-moon-800 hover:border-moon-750 hover:bg-white/[.20] [&>a]:text-moon-800">
          <ContactSalesCTA shouldUnderline={false} />
        </Button>
        <Button
          variant="ghost"
          onClick={onDismiss}
          className="text-moon-800 hover:bg-white/[.20]">
          Dismiss
        </Button>
      </div>
    );
  } else {
    return <span>{ctaNode}</span>;
  }
}

function getApproachingLimitBannerData(
  planName: PlanName,
  weaveUsageBytes: number,
  weaveLimitBytes: number,
  privileges: Privileges,
  onDismiss?: () => void
) {
  const fractionLeft = 1 - weaveUsageBytes / weaveLimitBytes;
  const fractionLeftText = fractionLeft.toLocaleString('us-en', {
    style: 'percent',
    maximumFractionDigits: 0,
  });
  const boldText = `You have ${fractionLeftText} of your ${numeral(
    weaveLimitBytes
  ).format('0 b')} free Weave ingestion remaining.`;
  const weaveOverageCostCents =
    privileges.weaveLimits?.weaveOverageCostCents ?? 0;
  const weaveOverageUnit = privileges.weaveLimits?.weaveOverageUnit ?? 'MB';
  const formattedCost = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(weaveOverageCostCents / 100);
  let statusMsg =
    'Once you exceed your limit, access to Weave will be restricted. ';
  if (planName === 'pro_plan_annual') {
    statusMsg = `Each additional ${weaveOverageUnit} will be charged at ${formattedCost} per ${weaveOverageUnit}. `;
  }
  if (planName === 'pro_plan_annual') {
    return {
      approachingLimitBoldText: boldText,
      approachingLimitStatusMsg: statusMsg,
      approachingLimitCTA: (
        <div className="ml-auto flex items-center gap-8">
          <Button
            variant="outline"
            className="border-moon-750 bg-transparent text-moon-800 hover:border-moon-750 hover:bg-white/[.20] [&>a]:text-moon-800">
            <ContactSalesCTA shouldUnderline={false} />
          </Button>
          <Button
            variant="ghost"
            onClick={onDismiss}
            className="text-moon-800 hover:bg-white/[.20]">
            Dismiss
          </Button>
        </div>
      ),
    };
  } else {
    return {
      approachingLimitBoldText: boldText,
      approachingLimitStatusMsg: statusMsg,
      approachingLimitCTA: (
        <span>
          <ContactSalesCTA />
        </span>
      ),
    };
  }
}

export async function getWeaveEnforcementBannerFlags(
  account: Account,
  viewerOrg?: ViewerOrg
): Promise<WeaveEnforcementBannerFlags> {
  const flags: WeaveEnforcementBannerFlags = {};
  if (
    account.accountType === AccountType.Personal ||
    envIsLocal ||
    viewerOrg == null
  ) {
    return flags;
  }

  const primarySub = getPrimarySub(viewerOrg);
  // don't show banners if user is on a Reverse Trial
  if (primarySub != null && isTrialPlanPrimaryPlan(primarySub.plan)) {
    return flags;
  }

  const [privileges, hasEnterpriseOrbSubscription, weaveLimits] =
    await Promise.all([
      getAccountPrivileges(account),
      getHasEnterpriseOrbSubscription(account),
      getAccountWeaveLimit(account),
    ]);
  const billingPeriod = getWeaveOverageSubBillingPeriod(privileges, viewerOrg);
  const weaveUsageBytes = await getWeaveUsageBytes(
    account,
    billingPeriod,
    viewerOrg
  );
  const weaveLimitBytes = weaveLimits?.weaveLimitBytes;
  // This plan doesn't have a limit on Weave usage
  if (weaveLimitBytes == null) {
    return flags;
  }

  // Don't set any banners/enforcement if they're a paying enterprise org either!!!
  if (hasEnterpriseOrbSubscription) {
    return flags;
  }

  const planName = primarySub?.plan.name as PlanName;
  flags.planName = planName;
  flags.weaveUsageBytes = weaveUsageBytes;
  flags.weaveLimitBytes = weaveLimitBytes;
  const cta =
    primarySub && canSeeTrialEndPage(primarySub) ? (
      <UpgradePlanCTA />
    ) : (
      <ContactSalesCTA />
    );
  const weaveOverageCostCents = privileges.weaveLimits?.weaveOverageCostCents;
  const weaveOverageUnit = privileges.weaveLimits?.weaveOverageUnit;
  if (
    weaveUsageBytes >= weaveLimitBytes * 0.9 &&
    weaveUsageBytes < weaveLimitBytes
  ) {
    const {
      approachingLimitBoldText,
      approachingLimitStatusMsg,
      approachingLimitCTA,
    } = getApproachingLimitBannerData(
      planName,
      weaveUsageBytes,
      weaveLimitBytes,
      privileges
    );
    flags.weaveApproachingLimitBannerProps = {
      organizationId: account.id ?? '',
      organizationName: account.name ?? '',
      approachingLimitStatusMsg,
      approachingLimitBoldText,
      approachingLimitCTA,
      planName,
    };
  } else if (
    weaveUsageBytes >= weaveLimitBytes &&
    !(
      account.isEnterprise === true ||
      (primarySub != null && isProPrimarySubscription(primarySub))
    )
  ) {
    const {exceededLimitBoldStatusMsg, exceededLimitStatusMsg} =
      getExceededLimitStatusMsgs(
        planName,
        weaveUsageBytes,
        weaveLimitBytes,
        weaveOverageCostCents,
        weaveOverageUnit
      );
    const finalCTA = getExceededLimitCTA(planName, cta);
    flags.weaveExceededLimitBannerProps = {
      organizationId: account.id ?? '',
      weaveUsageBytes,
      weaveLimitBytes,
      exceededLimitBoldStatusMsg,
      exceededLimitStatusMsg,
      exceededLimitCTA: finalCTA,
      planName,
    };
  } else if (account?.isEnterprise === true && !hasEnterpriseOrbSubscription) {
    const weaveTrialEndDate = privileges.weaveLimitDateInterval.end;
    const numDaysRemaining = differenceInDays(weaveTrialEndDate, TODAY);
    // Warn that the trial will expire soon
    flags.weaveTrialBannerProps = {
      organizationId: account.id ?? '',
      numDaysRemaining: numDaysRemaining,
    };
  }
  return flags;
}
