import {
  OrganizationDataForFailedPaymentBanner,
  OrganizationSubscriptionsQuery,
} from '../../generated/graphql';
import {Org} from '../../util/accounts/pricing';
import {Account} from '../Search/SearchNav/types';
import {BlockedRegionBannerProps} from './BlockedRegionBanners/utils';
import {type FreeAccountEnforcementBannerFlags} from './FreeAccountEnforcementBanners/utils';
import {type StorageBannerFlags} from './StorageEnforcementBanners/utils';
import {WeaveEnforcementBannerFlags} from './WeaveEnforcementBanners/types';

export type ViewerOrgs = NonNullable<
  OrganizationSubscriptionsQuery['viewer']
>['organizations'];
export type ViewerOrg = ViewerOrgs[number];

export enum LegacyNudgeBarType {
  StandardComputeHours = 'standard-compute-hours',
  AdvancedComputeHours = 'advanced-compute-hours',
  EnterpriseToStandard = 'enterprise-to-standard',
  EnterpriseToAdvanced = 'enterprise-to-advanced',
  UpgradeNudgeBarForExpiringSubscription = 'upgrade-nudge-bar-for-expiring-subscription',
  ContactUsNudgeBarForExpiringSubscription = 'contact-us-nudge-bar-for-expiraing-subscription',
  ExpiredSubscriptionEnforcementWithUpgrade = 'expired-subscription-enforcement-with-upgrade',
  ExpiredSubscriptionEnforcementWithContactUs = 'expired-subscription-enforcement-with-contact-us',
  DisabledSubscriptionEnforcement = 'disabled-subscription-enforcement',
}

export type LegacyNudgeBarInfo = {
  type: LegacyNudgeBarType;
  maxComputeHours?: number;
  aggressive?: boolean;
  daysUntilEnforcement?: number;
};

export type ExceededTeamsPlanLimitBannerProps = {
  organizationId: string;
  billingInterval: 'monthly' | 'annual';
  savingsIfSwitchToAnnual?: number;
  organizationName: string;
  computeSecondsUsed: number;
  computeSecondsLimit: number;
  isBillingAdmin: boolean;
  billingAdminEmail?: string | null;
};

export type ReachingTeamsPlanLimitBannerProps = {
  organizationId: string;
  organizationName: string;
  computeSecondsUsed: number;
  computeSecondsLimit: number;
  billingInterval: 'monthly' | 'annual';
  isBillingAdmin: boolean;
  billingAdminEmail?: string | null;
};

export type TeamsPlanBannerFlags = {
  reachingTeamsPlanLimitBannerProps?: ReachingTeamsPlanLimitBannerProps;
  exceededTeamsPlanLimitBannerProps?: ExceededTeamsPlanLimitBannerProps;
};

export type GlobalBannerState = {
  account: Account;

  // legacy stuff

  /** @deprecated pls use account to match appropriate state */
  organization: Org | null;
  renderedNudgeBar: LegacyNudgeBarType | null;
  maxHours: number | null;
  aggressive?: boolean;
  daysUntilEnforcement?: number;

  // new way of retrieving banner information
  storageAndEnforcementBannerFlags: StorageBannerFlags;
  teamsPlanEnforcementBannerFlags: TeamsPlanBannerFlags;
  failedPaymentOrgData: OrganizationDataForFailedPaymentBanner | null;
  freeAccountEnforcementBannerFlags: FreeAccountEnforcementBannerFlags;
  disabledSubscriptionBannerFlags: boolean;
  blockedRegionBannerProps: BlockedRegionBannerProps;
  weaveEnforcementBannerFlags: WeaveEnforcementBannerFlags;
};

export type GlobalBannerStates = {
  states: GlobalBannerState[] | null;
};
