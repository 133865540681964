import {useMemo} from 'react';

import {RunHistoryKeyInfoKeys} from '../../../../../types/run';
import {useKeyInfoQueryContext} from '../../../../MultiRunWorkspace/KeyInfoQueryContext';
import {OrganizedSettings} from '../../../../PanelBank/types';
import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {Complete, LinePlotSettings, XAxisFormatValues} from '../../types';
import {
  DEFAULT_X_AXIS_SETTINGS,
  getLinePlotSettingWithDefault,
} from '../linePlotDefaults';
import {getCascadingExcludeOutlierSetting} from './getCascadingExcludeOutliers';
import {getCascadingMaxRuns} from './getCascadingMaxRuns';
import {
  getDerivedPointVisualizationOption,
  useCascadingPointAggregationMethod,
} from './getCascadingPointAggregation';
import {getCascadingShowLegend} from './getCascadingShowLegend';

/**
 * We want to make sure the setting fields exist, but still allow them to be undefined.
 * When setting value is null, it indicates that a user hasn't modified the setting
 * yet. A few values are handled differently at workspace and section level, so those
 * individual hooks should handle those there.
 */
const getLinePlotSettings = (
  linePlotSettings: LinePlotSettings | undefined,
  historyKeys: RunHistoryKeyInfoKeys
): Complete<
  Omit<LinePlotSettings, 'pointVisualizationMethod' | 'excludeOutliers'>
> => {
  const sanitizedXAxis = getSanitizedXAxis(
    linePlotSettings?.xAxis,
    historyKeys
  );

  return {
    colorRunNames: linePlotSettings?.colorRunNames,
    coordinateZooming: linePlotSettings?.coordinateZooming,
    displayFullRunName: linePlotSettings?.displayFullRunName,
    groupAgg: linePlotSettings?.groupAgg,
    highlightedCompanionRunOnly: linePlotSettings?.highlightedCompanionRunOnly,
    limit: linePlotSettings?.limit,
    maxRuns: linePlotSettings?.maxRuns,
    showLegend: getCascadingShowLegend({
      // @ts-expect-error - suppressLegends is deprecated
      suppressLegends: linePlotSettings?.suppressLegends,
      showLegend: linePlotSettings?.showLegend,
    }),
    smoothingType: linePlotSettings?.smoothingType,
    smoothingWeight: linePlotSettings?.smoothingWeight,
    tooltipNumberOfRuns: linePlotSettings?.tooltipNumberOfRuns,
    useRunsTableGroupingInPanels:
      linePlotSettings?.useRunsTableGroupingInPanels,
    xAxis: sanitizedXAxis,
    xAxisFormat: linePlotSettings?.xAxisFormat,
    xAxisMax: linePlotSettings?.xAxisMax,
    xAxisMin: linePlotSettings?.xAxisMin,
    xLogScale: linePlotSettings?.xLogScale,
    yAxisMax: linePlotSettings?.yAxisMax,
    yAxisMin: linePlotSettings?.yAxisMin,
    yLogScale: linePlotSettings?.yLogScale,
  };
};

const getSanitizedXAxis = (
  xAxis: string | undefined,
  historyKeys: RunHistoryKeyInfoKeys
) => {
  if (xAxis == null) {
    return undefined;
  }

  // Also known as "Relative Time (Wall)". This is a special case where we don't want to check against historyKeys
  // because it will never exist in there. The points for this xAxis are derived from "_runtime" and "_timestamp".
  // See https://github.com/wandb/core/blob/master/frontends/app/src/components/PanelRunsLinePlot/timePlots/readme.md for more details.
  // See "usePostProcessedData" that's used in "PanelDataContext" on how those points are created.
  if (xAxis === '_absolute_runtime') {
    return xAxis;
  }

  // If the xAxis value is not in historyKeys, then it's an invalid value and we'll default
  // to w&b _step instead
  return historyKeys[xAxis] == null ? DEFAULT_X_AXIS_SETTINGS.xAxis : xAxis;
};

// All fields in the type should exist, but can be undefined
export const useWorkspaceLinePlotSettings = (
  organizedSettings?: OrganizedSettings
): Complete<LinePlotSettings> => {
  const linePlotSettings = organizedSettings?.linePlot;

  // This feature is still under development
  // Note: once feature is fully released on prod, we can remove this hook
  const {pointVisualizationMethod} =
    useCascadingPointAggregationMethod(linePlotSettings);

  const {
    keyInfoQuery: {historyKeyInfo},
  } = useKeyInfoQueryContext();

  return useMemo(
    () => ({
      ...getLinePlotSettings(linePlotSettings, historyKeyInfo.keys),
      pointVisualizationMethod,
      excludeOutliers: getCascadingExcludeOutlierSetting(
        pointVisualizationMethod,
        linePlotSettings
      ),
    }),
    [linePlotSettings, pointVisualizationMethod, historyKeyInfo.keys]
  );
};

// All fields in the type should exist, but can be undefined
export const useSectionLinePlotSettings = (
  organizedSettings?: OrganizedSettings
): Complete<LinePlotSettings> => {
  const linePlotSettings = organizedSettings?.linePlot;
  const {
    keyInfoQuery: {historyKeyInfo},
  } = useKeyInfoQueryContext();

  return useMemo(
    () => ({
      ...getLinePlotSettings(linePlotSettings, historyKeyInfo.keys),
      pointVisualizationMethod: linePlotSettings?.pointVisualizationMethod,
      // We don't need to worry about the deprecated excludeOutliers fields because
      // section settings came after those changes
      excludeOutliers: linePlotSettings?.excludeOutliers,
    }),
    [linePlotSettings, historyKeyInfo.keys]
  );
};

/**
 * @param settingsType indicates what level the settings are
 *
 * This is used at the panel/most specific level. It will take the current value or the default value.
 * We want to resolve at the panel level, so that we can easily add or remove settings
 * at both the workspace and section levels.
 */
export const getLinePlotSettingsWithDefaults = (
  settingsObj: LinePlotSettings | RunsLinePlotConfig | undefined
) => {
  const pointVisualizationMethod = getDerivedPointVisualizationOption(
    settingsObj?.pointVisualizationMethod
  );

  const limit = getCascadingMaxRuns({
    pointVisualizationMethod,
    maxRuns:
      settingsObj != null && 'maxRuns' in settingsObj
        ? settingsObj?.maxRuns
        : undefined,
    limit:
      settingsObj != null && 'limit' in settingsObj
        ? settingsObj?.limit
        : undefined,
  });

  const xAxisFormat = getLinePlotSettingWithDefault(
    settingsObj?.xAxisFormat,
    'xAxisFormat'
  );

  return {
    colorRunNames: getLinePlotSettingWithDefault(
      settingsObj?.colorRunNames,
      'colorRunNames'
    ),
    coordinateZooming: getLinePlotSettingWithDefault(
      settingsObj?.coordinateZooming,
      'coordinateZooming'
    ),
    displayFullRunName: getLinePlotSettingWithDefault(
      settingsObj?.displayFullRunName,
      'displayFullRunName'
    ),
    excludeOutliers: settingsObj?.excludeOutliers,
    groupAgg: getLinePlotSettingWithDefault(settingsObj?.groupAgg, 'groupAgg'),
    highlightedCompanionRunOnly: getLinePlotSettingWithDefault(
      settingsObj?.highlightedCompanionRunOnly,
      'highlightedCompanionRunOnly'
    ),
    limit: getLinePlotSettingWithDefault(limit, 'limit'),
    pointVisualizationMethod,
    showLegend: getLinePlotSettingWithDefault(
      settingsObj?.showLegend,
      'showLegend'
    ),
    smoothingType: getLinePlotSettingWithDefault(
      settingsObj?.smoothingType,
      'smoothingType'
    ),
    smoothingWeight: getLinePlotSettingWithDefault(
      settingsObj?.smoothingWeight,
      'smoothingWeight'
    ),
    tooltipNumberOfRuns: getLinePlotSettingWithDefault(
      settingsObj?.tooltipNumberOfRuns,
      'tooltipNumberOfRuns'
    ),
    useRunsTableGroupingInPanels: getLinePlotSettingWithDefault(
      settingsObj?.useRunsTableGroupingInPanels,
      'useRunsTableGroupingInPanels'
    ),
    xAxis: getLinePlotSettingWithDefault(settingsObj?.xAxis, 'xAxis'),
    xAxisMax: settingsObj?.xAxisMax,
    xAxisMin: settingsObj?.xAxisMin,
    xAxisFormat: xAxisFormat as XAxisFormatValues | undefined,
    xLogScale: getLinePlotSettingWithDefault(
      settingsObj?.xLogScale,
      'xLogScale'
    ),
    yAxisMax: settingsObj?.yAxisMax,
    yAxisMin: settingsObj?.yAxisMin,
    yLogScale: getLinePlotSettingWithDefault(
      settingsObj?.yLogScale,
      'yLogScale'
    ),
  };
};
