import {captureAndLogError} from '@wandb/weave/common/util/sentry';
import React, {createContext, useEffect, useMemo, useState} from 'react';

import {envIsPublicCloud} from '../../config';
import {
  FailedPaymentBanners,
  OrganizationDataForFailedPaymentBanner,
  useFailedPaymentBannersQuery,
  useOrganizationSubscriptionsQuery,
} from '../../generated/graphql';
import {useViewer} from '../../state/viewer/hooks';
import {Viewer} from '../../state/viewer/types';
import {
  getPrimarySub,
  isAccountMatchingOrg,
  isDisabledSubscription,
  PlanInfoWithActualPlans,
  useSubscriptionPlans,
} from '../../util/accounts/pricing';
import {useBetaFeatureDisableAccountEnforcementBanners} from '../../util/useBetaFeature';
import {Account} from '../Search/SearchNav/types';
import {useAccountList} from '../Search/SearchNav/useAccountList';
import {getFreeAccountEnforcementBannerFlags} from './FreeAccountEnforcementBanners/utils';
import {getLegacyNudgeBarTypeForViewerOrg} from './legacyNudgeBarUtils';
import {getStorageBannerFlags} from './StorageEnforcementBanners/utils';
import {getTeamsPlanBannerFlags} from './TeamsPlanEnforcementBanners/utils';
import {GlobalBannerState, GlobalBannerStates, ViewerOrgs} from './types';
import {getViewerOrSimulatedOrg, orgFromViewerOrg} from './utils';
import {getWeaveEnforcementBannerFlags} from './WeaveEnforcementBanners/utils';

export const GlobalBannerContext = createContext<GlobalBannerStates>({
  states: [],
});
GlobalBannerContext.displayName = 'GlobalBannerContext';

type GlobalBannerUpdaterState = {
  refetch: () => Promise<any>;
};
export const GlobalBannerUpdaterContext =
  createContext<GlobalBannerUpdaterState>({
    refetch: () => Promise.resolve(),
  });
GlobalBannerUpdaterContext.displayName = 'GlobalBannerUpdaterContext';

export const GlobalBannerContextProvider = React.memo(({children}) => {
  const {data, loading, refetch} = useOrganizationSubscriptionsQuery();
  const {planInfo} = useSubscriptionPlans();
  const {accountList} = useAccountList();

  const disableAccountEnforcementBanner =
    useBetaFeatureDisableAccountEnforcementBanners();

  const [contextValue, setContextValue] = useState<GlobalBannerStates>({
    states: [],
  });

  const viewer = useViewer();

  const paymentBannerQuery = useFailedPaymentBannersQuery({
    variables: {id: viewer?.id as string},
    skip: viewer?.id == null || !envIsPublicCloud,
  });

  const failedPaymentBanners = paymentBannerQuery.loading
    ? undefined
    : (paymentBannerQuery.data?.failedPaymentBanners as FailedPaymentBanners);

  useEffect(() => {
    // hack to prevent this call from running 5-7 times per page load, should probs
    // investigated further later.
    //
    // Utilizing react hooks will involve some restructing of these calls to happen
    // in the component context instead of arbitrary async function
    if (loading || planInfo == null) {
      return;
    }
    const viewerOrgs = data?.viewer?.organizations ?? [];
    const fetchGlobalBannerState = async () => {
      const states = await getGlobalBannerState(
        accountList,
        viewerOrgs,
        planInfo,
        viewer,
        failedPaymentBanners,
        disableAccountEnforcementBanner
      );
      setContextValue(states);
    };
    fetchGlobalBannerState();
  }, [
    planInfo,
    viewer,
    failedPaymentBanners,
    disableAccountEnforcementBanner,
    data?.viewer?.organizations,
    accountList,
    loading,
  ]);

  const updaterContextValue = useMemo(() => ({refetch}), [refetch]);

  return (
    <GlobalBannerContext.Provider value={contextValue}>
      <GlobalBannerUpdaterContext.Provider value={updaterContextValue}>
        {children}
      </GlobalBannerUpdaterContext.Provider>
    </GlobalBannerContext.Provider>
  );
});
GlobalBannerContextProvider.displayName = 'GlobalBannerContextProvider';

export default GlobalBannerContextProvider;

export async function getGlobalBannerState(
  accountList: Account[],
  viewerOrgs: ViewerOrgs,
  plans: PlanInfoWithActualPlans | null,
  viewer?: Viewer,
  failedPaymentBanners?: FailedPaymentBanners,
  disableAccountEnforcementBanner?: boolean
): Promise<GlobalBannerStates> {
  const states: GlobalBannerState[] = [];
  for (const account of accountList) {
    const viewerOrg = viewerOrgs.find(org =>
      isAccountMatchingOrg(account, org)
    );

    let organization = null;
    let legacyNudgeBarsForOrg = null;
    if (viewerOrg != null) {
      legacyNudgeBarsForOrg = getLegacyNudgeBarTypeForViewerOrg(
        getViewerOrSimulatedOrg(viewerOrg, plans)
      );
      organization = orgFromViewerOrg(viewerOrg);
    }
    const primarySub = getPrimarySub(viewerOrg);
    const disabledSubscriptionBannerFlags =
      primarySub != null && isDisabledSubscription(primarySub);

    const blockedRegionBannerProps = {
      entityId: account.id,
    };

    let teamsPlanEnforcementBannerFlags = {};
    let storageBannerFlags = {};
    let freeAccountEnforcementBannerFlags = {};
    let weaveEnforcementBannerFlags = {};
    if (disableAccountEnforcementBanner !== true) {
      try {
        [
          teamsPlanEnforcementBannerFlags,
          storageBannerFlags,
          freeAccountEnforcementBannerFlags,
          weaveEnforcementBannerFlags,
        ] = await Promise.all([
          getTeamsPlanBannerFlags(viewer, viewerOrg),
          getStorageBannerFlags(account, viewer, viewerOrg),
          getFreeAccountEnforcementBannerFlags(viewer, viewerOrg),
          getWeaveEnforcementBannerFlags(account, viewerOrg),
        ]);
      } catch (error) {
        const msg = `Error getting banner flags for org ${account.name}: ${error}`;
        captureAndLogError(msg, {fingerprint: ['getGlobalBannerState']});
      }
    }

    let orgFailedPaymentBanner: OrganizationDataForFailedPaymentBanner | null =
      null;
    if (failedPaymentBanners) {
      orgFailedPaymentBanner =
        failedPaymentBanners.orgsWithFailedPayments.find(
          b => b?.orgName === account.name
        ) ?? null;
    }

    if (legacyNudgeBarsForOrg != null) {
      const renderedNudgeBar = legacyNudgeBarsForOrg.type;
      const maxHours = legacyNudgeBarsForOrg.maxComputeHours ?? null;
      const aggressive = legacyNudgeBarsForOrg.aggressive;
      const daysUntilEnforcement = legacyNudgeBarsForOrg.daysUntilEnforcement;
      states.push({
        account,
        organization,
        renderedNudgeBar,
        maxHours,
        aggressive,
        daysUntilEnforcement,
        storageAndEnforcementBannerFlags: storageBannerFlags,
        teamsPlanEnforcementBannerFlags,
        failedPaymentOrgData: orgFailedPaymentBanner,
        freeAccountEnforcementBannerFlags,
        disabledSubscriptionBannerFlags,
        blockedRegionBannerProps,
        weaveEnforcementBannerFlags,
      });
    } else {
      states.push({
        account,
        organization,
        renderedNudgeBar: null,
        maxHours: null,
        storageAndEnforcementBannerFlags: storageBannerFlags,
        teamsPlanEnforcementBannerFlags,
        failedPaymentOrgData: orgFailedPaymentBanner,
        freeAccountEnforcementBannerFlags,
        disabledSubscriptionBannerFlags,
        blockedRegionBannerProps,
        weaveEnforcementBannerFlags,
      });
    }
  }

  return {states};
}
