import React, {memo, useContext} from 'react';

import {TeamOrgQuery} from '../generated/graphql';
import {isAccountMatchingOrg} from '../util/accounts/pricing';
import {useCurrentTeamOrg, useShowNudgeBar} from '../util/nudgeBars';
import {AggressiveRedirectEnforcement} from './AggressiveRedirectEnforcement';
import {GlobalBannerContext} from './GlobalBanners/GlobalBannerContextProvider';
import {LegacyNudgeBarType} from './GlobalBanners/types';

const DisabledSubscriptionEnforcementComp = () => {
  const {states} = useContext(GlobalBannerContext);

  const currentTeamOrg: TeamOrgQuery | null = useCurrentTeamOrg();
  const showNudgeBar = useShowNudgeBar();

  // no state means that this viewer isn't in any organization that should be nudged or enforced
  if (states == null || states.length === 0) {
    return null;
  }

  // on /usage or /subscriptions page, we don't show nudge bar
  if (!showNudgeBar) {
    return null;
  }

  const currentOrganizationStates = states?.filter(state =>
    isAccountMatchingOrg(state.account, currentTeamOrg?.entity?.organization)
  );

  // organizations without state are assumed to be in good standing and thus don't need a nudge bar
  if (currentOrganizationStates == null) {
    return null;
  }

  const disabledState = currentOrganizationStates.find(
    state =>
      state.renderedNudgeBar ===
      LegacyNudgeBarType.DisabledSubscriptionEnforcement
  );

  // this organization's subscription is not disabled and thus don't need a nudge bar
  if (disabledState == null) {
    return null;
  }

  return (
    <AggressiveRedirectEnforcement
      analyticsLocation="disabled subscription enforcement"
      organizationName={currentTeamOrg?.entity?.organization?.name}
      entityName={currentTeamOrg?.entity?.name}
    />
  );
};

export const DisabledSubscriptionEnforcement = memo(
  DisabledSubscriptionEnforcementComp
);
