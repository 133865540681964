import {Button} from '@wandb/weave/components/Button';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {memo, useContext} from 'react';

import {AccountStatusBanner} from '../../../../components/AccountStatusBanner/AccountStatusBanner';
import {
  BannerBoldText,
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../../../components/Banner';
import {useCurrentBannerStates} from '../../../../components/GlobalBanners/useCurrentBannerStates';
import {AccountSelectorContext} from '../../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {Analytics} from '../../../../services/analytics';

const DisabledSubscriptionWarningBannerComp = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const currentBannerStates = useCurrentBannerStates();
  if (
    currentBannerStates.every(
      state => state.disabledSubscriptionBannerFlags === false
    )
  ) {
    return null;
  }

  return (
    <Tailwind>
      <AccountStatusBanner
        data-test="disabled-subscription-warning-banner"
        eventData={{
          location: 'disabled subscription warning banner',
          organizationName: selectedAccount?.name,
        }}>
        <BannerFlexWrapper>
          <Icon name="warning" />
          <BannerTextWrapper>
            <BannerBoldText>
              Your subscription has been deactivated.
            </BannerBoldText>
            Please email a sales representative at{' '}
            <a href="mailto:ar@wandb.com" className="font-semibold underline">
              ar@wandb.com
            </a>{' '}
            to continue using W&B without disruption.
          </BannerTextWrapper>
        </BannerFlexWrapper>
        <a href="mailto:ar@wandb.com">
          <Button
            variant="ghost"
            className="bg-transparent outline hover:bg-transparent hover:text-moon-600"
            onClick={() => {
              Analytics.track('Contact Us Button Clicked', {
                location: 'disabled subscription warning banner',
                organizationName: selectedAccount?.name,
              });
            }}>
            Contact Us
          </Button>
        </a>
      </AccountStatusBanner>
    </Tailwind>
  );
};

export const DisabledSubscriptionWarningBanner = memo(
  DisabledSubscriptionWarningBannerComp
);
