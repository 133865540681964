import {DAY, SECONDS_IN_HOUR} from '@wandb/weave/common/util/time';
import produce from 'immer';
import _ from 'lodash';

import {
  ExpansionPackConnection,
  OrganizationSubscription,
  OrganizationSubscriptionType,
} from '../../generated/graphql';
import {
  Org,
  PlanInfoWithActualPlans,
  PLANS_WITH_COMPUTE_HOUR_LIMIT,
} from '../../util/accounts/pricing';
import {
  getAggressiveCookie,
  getComputeHourLimitCookie,
  getDaysUntilExpireCookie,
  getOrganizationIDCookie,
  getSubscriptionStatusCookie,
  getSubscriptionTypeCookie,
} from '../../util/usageLimit';
import {type ViewerOrg} from './types';

const DEFAULT_SEATS_LIMIT = 100;

export function getComputeHourSubscription(viewerOrg: ViewerOrg) {
  const computeHourSub = viewerOrg.subscriptions.find(
    sub =>
      typeof sub.privileges.compute_hours === 'number' &&
      _.includes(PLANS_WITH_COMPUTE_HOUR_LIMIT, sub.plan.name)
  );
  return computeHourSub;
}

export function getViewerOrSimulatedOrg(
  viewerOrg: ViewerOrg,
  plans: PlanInfoWithActualPlans | null
): ViewerOrg {
  const simulatedOrgID = getOrganizationIDCookie();
  const simulatedSubscription = getSimulatedSubscription(plans);

  if (viewerOrg.id !== simulatedOrgID || simulatedSubscription == null) {
    return viewerOrg;
  }

  return produce(viewerOrg, draft => {
    /**
     * The following ignore is a hack on the types because trying to selectively remove the __typename property from each ViewerOrg['subscriptions'] is eluding me. This is an artifact of the problem of coupling app code to autogenerated types detailed here: https://www.notion.so/wandbai/Reduce-coupling-and-improve-type-legibility-for-autogenerated-API-queries-cd97dd45172b4c5ab807a059b799e2f0#603a8d3bd6524de8a138cc620e9c5092
     */
    // @ts-ignore
    draft.subscriptions = [simulatedSubscription];

    const simulatedAggressive = getAggressiveCookie();

    if (simulatedAggressive) {
      // simulate the situation where users used excessive compute hours
      // for more than 14 days and ignored the warning nudge banner
      const computeHourSub = getComputeHourSubscription(draft);
      const maxComputeHour = computeHourSub?.privileges.compute_hours as
        | number
        | undefined;
      if (maxComputeHour != null) {
        const simulatedOldComputeHours = maxComputeHour * SECONDS_IN_HOUR;
        // set the first team to have the exceeding oldComputeHour and set the rest to 0
        draft.teams.forEach((team, i) => {
          team.oldComputeHours = i === 0 ? simulatedOldComputeHours : 0;
        });
      }
    } else {
      // simulate the situation where users don't have oldComputeHour to ignore nudge bar
      draft.teams.forEach(team => {
        team.oldComputeHours = 0;
      });
    }

    const simulatedComputeHour = getComputeHourLimitCookie();
    if (simulatedComputeHour != null) {
      const simulatedComputeHours = simulatedComputeHour * SECONDS_IN_HOUR;
      // set the first team to have the exceeding oldComputeHour and set the rest to 0
      draft.teams.forEach((team, i) => {
        team.computeHours = i === 0 ? simulatedComputeHours : 0;
      });
    }
  });
}

const emptyExpansionPackConnection: ExpansionPackConnection = {
  __typename: 'ExpansionPackConnection',
  edges: [],
};

const COMMON_SUBSCRIPTION_PROPERTIES = {
  seats: DEFAULT_SEATS_LIMIT,
  availableSeatsToPurchase: 0,
  __typename: 'OrganizationSubscription',
  nextPlans: [],
  isAutomaticUpgrade: false,
  createdAt: new Date().toISOString(),
  expansionPacks: emptyExpansionPackConnection,
};

export function getSimulatedSubscription(
  plans: PlanInfoWithActualPlans | null
): Omit<OrganizationSubscription, '__typename'> | null {
  if (plans == null) {
    return null;
  }

  const subscriptionType = getSubscriptionTypeCookie();
  const simulatedDaysUntilExpiration = getDaysUntilExpireCookie();
  const expiresAt = getSimulatedExpiresAt(simulatedDaysUntilExpiration);
  const status = getSubscriptionStatusCookie();

  const id = 'simulatedSubscriptionID';

  const enterprisePlan = plans.enterprisePlan;

  switch (subscriptionType) {
    case 'free_academic_trial':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: enterprisePlan,
        privileges: {num_private_teams: 3},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.AcademicTrial,
      };
    case 'free_enterprise_plan_trial':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: enterprisePlan,
        privileges: {num_private_teams: 3},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.ManualTrial,
      };
    case 'enterprise_plan':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: enterprisePlan,
        privileges: {num_private_teams: 5},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.Enterprise,
      };
    default:
      return null;
  }
}

export function getSimulatedExpiresAt(
  daysUntilExpiration: number | null
): string | null {
  if (daysUntilExpiration == null) {
    return null;
  }

  const expiresAt = new Date();
  expiresAt.setDate(expiresAt.getDate() + daysUntilExpiration);

  return expiresAt.toISOString();
}

export function orgFromViewerOrg(org: ViewerOrg): Org {
  let flags = {};
  try {
    if (org.flags != null) {
      flags = JSON.parse(org.flags);
    }
  } catch {
    // do nothing
  }

  return {
    id: org.id,
    name: org.name,
    usedSeats: org.usedSeats,
    teams: org.teams,
    subscriptions: org.subscriptions,
    flags,
  };
}

export function isDateLessThanThreeDaysOld(date: Date): boolean {
  const currDate = new Date(date).getTime();
  const threeDaysAgo = Date.now() - 3 * DAY;
  return currDate > threeDaysAgo;
}
