import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC, Fragment} from 'react';

import {createDismissibleBanner} from '../../../pages/Billing/AccountSettings/WarningBanner/DismissibleBanner';
import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
import {useCurrentBannerStates} from '../useCurrentBannerStates';
import {WeaveExceededLimitBannerProps} from './types';

export const WeaveExceededLimitBannerGlobalList: FC = () => {
  const currentBannerStates = useCurrentBannerStates();

  return (
    <Tailwind>
      <div className="space-y-8">
        {currentBannerStates.map(state => {
          const weaveExceededLimitBannerProps =
            state.weaveEnforcementBannerFlags.weaveExceededLimitBannerProps;

          return (
            <Fragment key={state.account.name + state.account.accountType}>
              {weaveExceededLimitBannerProps && (
                <WeaveExceededLimitBanner {...weaveExceededLimitBannerProps} />
              )}
            </Fragment>
          );
        })}
      </div>
    </Tailwind>
  );
};

export const WeaveExceededLimitBanner =
  createDismissibleBanner<WeaveExceededLimitBannerProps>({
    id: ({organizationId}) => {
      return `weave-exceeded-limit-banner-${organizationId}`;
    },
    Component: ({
      organizationId,
      exceededLimitBoldStatusMsg,
      exceededLimitStatusMsg,
      exceededLimitCTA,
      planName,
    }) => {
      return (
        <Tailwind>
          <AccountStatusBanner
            eventData={{
              location: `exceeded 100% for Weave alert banner`,
              organizationId,
              resource: 'Weave',
              planName,
            }}>
            <AccountStatusBannerFlexWrapper>
              <div>
                <Icon name="info" />
              </div>
              <AccountStatusBannerTextWrapper>
                <span>
                  <AccountStatusBannerBoldText>
                    {exceededLimitBoldStatusMsg}
                  </AccountStatusBannerBoldText>{' '}
                  <span>{exceededLimitStatusMsg}</span>
                </span>
              </AccountStatusBannerTextWrapper>
              {exceededLimitCTA}
            </AccountStatusBannerFlexWrapper>
          </AccountStatusBanner>
        </Tailwind>
      );
    },
  });
